<template>
    <page-content>

        <common-table
                ref="table"
                path="web/beian/product"
                :query-params="queryParams"
                :columns="columns">

            <template slot="search">
                <a-button  ghost type="primary" @click="add">新增</a-button>

                <a-radio-group style="margin-left: 20px" v-model="queryParams.disable" @change="$refs.table.handleReset()">
                    <a-radio-button :value="null">全部</a-radio-button>
                    <a-radio-button :value="false">已上架</a-radio-button>
                    <a-radio-button :value="true">已下架</a-radio-button>
                </a-radio-group>

            </template>

            <template slot="commission1" slot-scope="{record}">
                <a-tag>{{record.commissionM1 || 0}}</a-tag>
                <a-tag>{{record.commissionX1 || 0}}</a-tag>
                <a-tag>{{record.commissionU1 || 0}}</a-tag>
            </template>

            <template slot="commission2" slot-scope="{record}">
                <a-tag>{{record.commissionM2 || 0}}</a-tag>
                <a-tag>{{record.commissionX2 || 0}}</a-tag>
                <a-tag>{{record.commissionP || 0}}</a-tag>
                <a-tag>{{record.commissionU2 || 0}}</a-tag>
            </template>

            <template slot="operation" slot-scope="{record}">
                <action-edit @click="edit(record)"></action-edit>
                <action-delete @click="remove(record)"></action-delete>
            </template>

        </common-table>

        <ProductEdit ref="editDrawer" @success="getList"></ProductEdit>

    </page-content>
</template>
<script>
    import ProductEdit from "./ProductEdit";
    export default {
        components: {ProductEdit},
        data() {
            return {
                queryParams:{disable:false}
            }
        },

        computed: {
            columns() {
                let rateRender = function (text) {
                    return text + "%"
                }
                return [
                    {
                        title: '名称',
                        dataIndex: 'name',
                    },
                    {
                        title: '佣金',
                        dataIndex: 'commission',
                        customRender(text){
                            return text/100 +"元/套"
                        }
                    },
                    {
                        title: '管理团队分佣',
                        dataIndex: 'commissionM',
                        customRender:rateRender
                    },
                    {
                        title: '陆志敏分佣',
                        dataIndex: 'commissionP2',
                        customRender:rateRender
                    },
                    {
                        title: '一级分佣',
                        dataIndex: 'commissionP1',
                        customRender:rateRender
                    },
                    {
                        title: '介绍人分佣',
                        dataIndex: 'commissionU',
                        customRender:rateRender
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'createdAt'
                    },
                    {
                        title: '操作',
                        scopedSlots: {customRender: 'operation'}
                    }
                ]
            }
        },
        methods: {
            add() {
                this.$refs.editDrawer.show()
            },
            edit(record) {
                this.$refs.editDrawer.show(record)
            },
            getList() {
                this.$refs.table.getData()
            },
            remove (record) {
                let that = this
                this.$confirm({
                    title: '是否删除该产品?',
                    content: '该操作不可撤销',
                    centered: true,
                    onOk () {
                        that.$delete('web/beian/product/' + record.id).then(() => {
                            that.$message.success('删除成功')
                            that.getList()
                        })
                    }
                })
            },

        }
    }
</script>
<style lang="less" scoped>

    .news-logo {
        width: 60px;
        height: 60px;
    }
</style>
